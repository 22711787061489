// 2024-09-09 13:33:56 +0700

export default {
  customerService: 'Customer service',
  insuranceProducts: 'Insurance products',
  carInsurance: 'Car insurance',
  ourStory: 'Our story',
  contactUs: 'Contact us',
  aboutUs: 'About us',
  privacyPolicy: 'Privacy policy',
  termsAndConditions: 'Terms and conditions',
  cancellationAndRefund: 'Cancellation and refund',
  legal: 'Legal',
  sitemap: 'Sitemap',
  blog: 'Blog',
  allRightsReserved: 'All rights reserved',
  signOut: 'Sign out',
  dashboard: 'Dashboard',
  billing: 'Billing',
  policy: 'Policy',
  password: 'Password',
  personalInfo: 'Personal info',
  account: 'Account',
  renewPolicy: 'Renew policy',
  claim: 'Claim',
  discount: 'Discount',
  signIn: 'Sign in',
  request: 'Request',
  update: 'Update',
  personalInformation: 'Personal information',
  edit: 'Edit',
  claims: 'Claims',
  payNow: 'Pay Now',
  noData: 'No data',
  viewMore: 'View more',
  expiryDate: 'Expiry date',
  policyNumber: 'Policy number',
  orderNumber: 'Order number',
  theInsured: 'The insured',
  theInsuredPolicyholder: 'Insured / Policy holder',
  claimForm: 'Claim form',
  insurer: 'Insurer',
  policyType: 'Policy type',
  yes: 'Yes',
  no: 'No',
  yesHave: 'Yes',
  submit: 'Submit',
  learnMore: 'Learn more',
  recommendedReading: 'Recommended reading',
  compare: 'Compare',
  remove: 'Remove',
  removeAll: 'Remove all',
  back: 'Back',
  unknown: 'Unknown',
  details: 'Details',
  clickToViewDetails: 'Click to view details',
  status: 'Status',
  renew: 'Renew',
  startDate: 'Start date',
  endDate: 'End date',
  proceedToPayment: 'Proceed to payment',
  paySecurely: 'Pay securely',
  close: 'Close',
  viewDetails: 'View details',
  coverage: 'Coverage',
  covers: 'Covers',
  deductible: 'Deductible',
  exclusion: 'Exclusion',
  privateCar: 'Private car',
  sendNow: 'Send now',
  periodOfInsurance: 'Period of insurance',
  autoPrivateVehicleInsurance: 'Auto private vehicle insurance',
  paidDate: 'Paid date',
  declaration: 'Declaration',
  thirdPartyCover: 'Third-Party Liability Only',
  comprehensiveCover: 'Third-Party Liability + Own Damage + Theft',
  compareQuotes: 'Compare quotes',
  endorsement: 'Endorsement',
  change: 'Change',
  planType: 'Plan type',
  loading: 'Loading',
  quoteDetails: 'Quote Details',
  youWillPay: 'You\'ll pay',
  planSummary: 'Plan Summary',
  company: 'Company',
  licensedBy: 'Licensed by',
  weAccept: 'We accept',
  mgaCoverSummary: 'By MGA Asia Insurance Brokers, a licensed Insurance Broking Company in Cambodia.',
  others: 'Others',
  brokerLicense: {
    label: 'Broker license number',
    number: '252 SHV',
  },
  year: 'Year',
  month: 'Month',
  policyTerm: 'Policy term',
  premiumPaymentTerm: 'Premium payment term',
  and: 'And',
  resendOTP: 'Resend OTP',
  enterWrongNumber: 'Enter wrong number',
  articles: 'Articles',
  benefit: 'Benefit',
  vehicle: 'Vehicle',
  cambodia: 'Cambodia',
  noImage: 'No image',
  rejectedReason: 'Rejected reason',
  document: 'Document',
  documentType: 'Document type',
  renewable: 'Renewable',
  claimable: 'Claimable',
  startedAt: 'Started at',
  endedAt: 'Ended at',
  followUs: 'Follow us',
  goHome: 'Go to Home',
  delete: 'Delete',
  photos: 'Photos',
  managePhotos: 'Manage photos',
  emergencyCall: 'For emergency assistance 24/7, please call',
  featuredIn: 'Featured in',
  preview: 'Preview',
  latestArticles: 'Latest articles',
  home: 'Home',
  getQuote: 'Get quote',
  next: 'Next',
  required: 'Required',
  buyNow: 'Buy now',
  feature: 'Feature',
  myself: 'Myself',
  myAge: 'My age',
  spouse: 'Spouse',
  spouseAge: 'Spouse age',
  father: 'Father',
  fatherAge: 'Father age',
  mother: 'Mother',
  motherAge: 'Mother age',
  son: 'Son',
  sonAge: 'Son age',
  daughter: 'Daughter',
  daughterAge: 'Daughter age',
  role: 'Role',
  accountInformation: 'Account information',
  promotionCode: 'Promotion code',
  clientPolicy: 'Client policy',
  review: 'Review',
  summary: 'Summary',
  compareGuide: 'Add up to {{max}} quotes to compare',
  promotionDiscount: 'Promotion discount',
  healthInsurance: 'Health insurance',
  contactViaFacebook: 'Contact Us via Facebook',
  contactViaTelegram: 'Contact Us via Telegram',
  telephone: 'Telephone',
  sendQuoteToMyEmail: 'Send this quote to my email',
  me: 'Me',
  premium: 'Premium',
  copied: 'Copied',
  copyLink: 'Copy link',
  weight: 'Weight',
  height: 'Height',
  adminFee: 'Admin fee',
  policyWording: 'Policy wording',
  planBrochure: 'Plan brochure',
  personalAccidentInsurance: 'Personal accident insurance',
  premiumBreakdown: 'Premium Breakdown',
  thirdPartyCoverPremium: 'Third-Party Cover Premium',
  ownDamageTheftCoverPremium: 'Own Damage (OD) / Theft Cover Premium',
  totalPremium: 'Total premium',
  totalPremiumKhr: 'Total premium KHR',
  ownDamageTheftPremium: 'Own damage / theft premium',
  thirdPartyPremium: 'Third-party premium',
  accidentToDriverPremium: 'Accident to driver premium',
  passengerLiabilityPremium: 'Passenger liability premium',
  policyOverview: 'Policy overview',
  newClaim: 'New claim',
  claimedDate: 'Claimed date',
  planDetails: 'Plan details',
  cancerInsurance: 'Cancer insurance',
  frontlineNote: 'Frontline note',
  note: 'Note',
  policyDetails: 'Policy details',
  notSet: 'Not set',
  noteToInsurer: 'Note to insurer',
  fillPolicyDetails: 'Fill policy details',
  cancel: 'Cancel',
  tpl: 'Third-party liability (TPL)',
  coverValueIdv: 'Cover value IDV',
  od: 'Own damage (OD)',
  theft: 'Theft',
  ad: 'Accident to driver (AD)',
  pl: 'Passenger liability (PL)',
  included: 'Included',
  instance: 'Instance',
  frontlineUser: 'Frontline user',
};
