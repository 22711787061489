import { KeysToCamelCase, NumericString } from '@utils/typescript';
import { BusinessPartnerResponse } from './BusinessPartner';
import { CarResponse } from './Car';
import { CarPlanTypes } from './CarPlanType';
import { InsurerResponse } from './Insurer';
import { OrderStatuses } from './Order';
import { UserResponse } from './User';
import { UserCarResponse } from './UserCar';

export enum CarPlanThirdPartyCoverageTypes {
  DeathBodilyInjury = 'death_bodily_injury',
  PropertyDamage = 'property_damage',
  CostOfDefense = 'cost_of_defense',
  AnnualAggregateLimit = 'annual_aggregate_limit',
}

export enum CarPlanComprehensiveCoverageTypes {
  OwnDamageTheft = 'own_damage_theft',
}

export enum CarPlanAddonCoverageTypes {
  AccidentToDriver = 'accident_to_driver',
  PassengerLiability = 'passenger_liability',
}

export const CarPlanCoverType = {
  ...CarPlanThirdPartyCoverageTypes,
  ...CarPlanComprehensiveCoverageTypes,
};

export type CarPlanCover = {
  coverage_type: keyof typeof CarPlanCoverType;
  description: string;
};

export type CarQuotePremiumResponse = {
  third_party_amount: NumericString;
  third_party_discount_amount: NumericString;
  third_party_final_amount: NumericString;

  third_party_deductible_amount: NumericString;
  third_party_deductible_discount_amount: NumericString;
  third_party_deductible_final_amount: NumericString;

  own_damage_amount: NumericString;
  own_damage_discount_amount: NumericString;
  own_damage_final_amount: NumericString;

  own_damage_deductible_amount: NumericString;
  own_damage_deductible_discount_amount: NumericString;
  own_damage_deductible_final_amount: NumericString;

  theft_deductible_amount: NumericString;
  theft_deductible_discount_amount: NumericString;
  theft_deductible_final_amount: NumericString;

  accident_to_driver_amount: NumericString;
  accident_to_driver_discount_amount: NumericString;
  accident_to_driver_final_amount: NumericString;

  passenger_liability_amount: NumericString;
  passenger_liability_discount_amount: NumericString;
  passenger_liability_final_amount: NumericString;

  admin_fee_amount: NumericString;
  admin_fee_final_amount: NumericString;

  premium: NumericString;
  final_premium: NumericString;

  total_premium: NumericString;
  total_premium_khr: NumericString;
};

export type CarQuotePremium = KeysToCamelCase<CarQuotePremiumResponse>;

export type CarQuoteOptionResponse = {
  car_id: number;
  manufactured_year: number;
  plan_type: CarPlanTypes;
  idv_amount: NumericString;
  accident_to_driver: boolean;
  passenger_liability: boolean;
  promotion_code: string;
};

export type CarQuoteResponse = CarQuoteOptionResponse &
  CarQuotePremiumResponse & {
    car_plan_id: number;
    car_order_id: number;
    insurer: InsurerResponse;
    car: CarResponse;
    user: UserResponse;
    user_car: UserCarResponse;
    garages: BusinessPartnerResponse[];
    frontline_user: UserResponse;

    endorsements: string[];
    third_party_deductible_description: string;
    own_damage_deductible_description: string;
    theft_deductible_description: string;

    third_party_covers: CarPlanCover[];
    comprehensive_covers: CarPlanCover[];
    addon_covers: CarPlanCover[];

    exclusion_html: string;

    policy_wording?: {
      description: string;
      file: string;
    };

    frontline_note: string;
    activation_date: string;
    started_at: number;
    ended_at: number;
    status: OrderStatuses;
    policy_number: string;
  };

export type CarQuote = KeysToCamelCase<CarQuoteResponse>;
