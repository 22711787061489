import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { DELIMITER, getCarName } from '@models/Car';
import { CarQuote } from '@models/CarQuote';

type Props = Pick<CarQuote, 'userCar'> & {
  onEdit?: () => void;
};

const VehicleCardBody = ({ userCar, onEdit }: Props) => {
  const { t } = useTranslation();
  const { manufacturedYear, engineNumber, chassisNumber, registrationNumber, registrationPlace } = userCar;

  return (
    <Card.Body>
      <div className="d-flex justify-content-between">
        <Card.Title>{t('carQuoteBuyPage.carQuoteReview.vehicleTitle')}</Card.Title>
        {onEdit && (
          <Button onClick={onEdit} variant="outline-primary" size="sm">
            {t('common:edit')}
          </Button>
        )}
      </div>
      <ul className="list-unstyled mb-0">
        <li className="fw-bold">{getCarName(userCar, DELIMITER.SPACE)}</li>
        <li>
          {t('common:privateCar')} . {manufacturedYear}
        </li>
        <li>
          {t('car.engineNumber')}: {engineNumber}
        </li>
        <li>
          {t('car.chassisNumber')}: {chassisNumber}
        </li>
        <li>
          {t('car.registrationPlace')}: {registrationPlace}
        </li>
        <li>
          {t('car.registrationNumber')}: {registrationNumber}
        </li>
      </ul>
    </Card.Body>
  );
};

export { VehicleCardBody };
