import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, Col, Container, Row, Button, Stack } from 'react-bootstrap';
import { Formik, Form, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import CarQuotePremiumCardBody from '@components/Feature/CarQuote/CarQuotePremiumCardBody';
import { SupportCard } from '@components/Feature/Support/support-card';
import { FormGroupControl } from '@components/Base';

import { useCarQuoteEdit } from '../hook';
import { carQuoteMigrate, CarQuoteMigratePayload } from '../action';
import { FormValues } from './schema';
import { useInitialValues, useValidationSchema } from './hook';
import { getCarQuoteBuyReviewPageRoute } from '../route';

const GROUP_PROPS = { className: 'mb-3' };

const CarQuoteBuyMigratePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const carQuote = useCarQuoteEdit();
  const initialValues = useInitialValues();
  const validationSchema = useValidationSchema();

  if (!carQuote) return null;

  const { carOrderId } = carQuote;

  const handleCancel = () => {
    navigate(getCarQuoteBuyReviewPageRoute({ carOrderId }), { replace: true });
  };

  const handleSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const payload: CarQuoteMigratePayload = {
      ...values,
      carOrderId,
    };
    dispatch(carQuoteMigrate(payload));
    setSubmitting(false);
  };

  return (
    <Container className="my-3">
      <Row className="gy-5">
        <Col lg={8}>
          {initialValues && (
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
              {({ isSubmitting }) => (
                <Form>
                  <Card>
                    <Card.Body>
                      <FormGroupControl
                        type="date"
                        name="activationDate"
                        label={t('car.activationDate')}
                        groupProps={GROUP_PROPS}
                        required
                        min={moment().subtract(1, 'year').format('YYYY-MM-DD').toString()}
                        max={moment().add(3, 'month').format('YYYY-MM-DD').toString()}
                      />

                      <FormGroupControl
                        name="policyNumber"
                        label={t('common:policyNumber')}
                        groupProps={GROUP_PROPS}
                        required
                      />
                    </Card.Body>

                    <Card.Footer className="bg-white border-0 mx-auto my-4">
                      <Stack direction="horizontal" gap={3}>
                        <Button onClick={handleCancel} variant="outline-primary" size="lg">
                          {t('common:cancel')}
                        </Button>

                        <Button type="submit" variant="primary" size="lg" disabled={isSubmitting}>
                          Migrate
                        </Button>
                      </Stack>
                    </Card.Footer>
                  </Card>
                </Form>
              )}
            </Formik>
          )}
        </Col>

        <Col lg={4}>
          <Card className="mb-4">
            <CarQuotePremiumCardBody {...carQuote} />
          </Card>
          <SupportCard />
        </Col>
      </Row>
    </Container>
  );
};

export { CarQuoteBuyMigratePage };
