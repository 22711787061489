import { object, number, string } from 'yup';

import { CustomCarQuoteParams } from '@apis/CarQuoteApi';

export type FormValues = Omit<CustomCarQuoteParams, 'carOrderId'> & {
  customType: 'lump_sum' | 'custom';
};

export const formValuesParamsSchema = object({
  thirdPartyAmount: number(),
  thirdPartyDiscountAmount: number(),

  thirdPartyDeductibleAmount: number(),
  thirdPartyDeductibleDiscountAmount: number(),

  ownDamageAmount: number(),
  ownDamageDiscountAmount: number(),

  ownDamageDeductibleAmount: number(),
  ownDamageDeductibleDiscountAmount: number(),

  theftDeductibleAmount: number(),
  theftDeductibleDiscountAmount: number(),

  accidentToDriverAmount: number(),
  accidentToDriverDiscountAmount: number(),

  passengerLiabilityAmount: number(),
  passengerLiabilityDiscountAmount: number(),

  adminFeeAmount: number(),
  premium: number(),
  noClaimDiscountPercentage: number(),
  loyaltyDiscountPercentage: number(),

  customType: string().default('no_custom')
});
