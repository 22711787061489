import { object, boolean } from 'yup';

import { FormValues } from './schema';

export const useInitialValues = () => {
  const initialValues: FormValues = {
    confirmed: false,
  };

  return initialValues;
};

export const useValidationSchema = () => {
  return object({
    confirmed: boolean().oneOf([true]),
  });
};
