import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { object, date, string } from 'yup';
import { pick } from 'lodash';
import moment from 'moment-timezone';

import { mergeInitialValues } from '@utils/formik';
import { getFieldKeys } from '@utils/yup';
import { useParseParams } from '@hooks/search-params';

import { selectCarQuoteBuy } from '../selector';
import { FormValues, formValuesParamsSchema } from './schema';

export const useInitialValues = () => {
  const { carQuote } = useSelector(selectCarQuoteBuy);
  const initialValues = useParseParams<FormValues>(formValuesParamsSchema);

  return useMemo(() => {
    if (!carQuote) return;

    return mergeInitialValues(initialValues, pick(carQuote, getFieldKeys(formValuesParamsSchema)));
  }, [carQuote]);
};

export const useValidationSchema = () => {
  const { t } = useTranslation();

  return object({
    activationDate: date()
      .required(t('validation:required'))
      .min(moment().subtract(1, 'year'), 'must not exceed 1 year ago')
      .max(moment().add(3, 'month'), 'must not exceed 3 months from now'),
    policyNumber: string().required(t('validation:required')),
  });
};
