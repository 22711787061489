import { object, string } from 'yup';

import { MigrateCarQuoteParams } from '@apis/CarQuoteApi';

export type FormValues = Omit<MigrateCarQuoteParams, 'carOrderId'>;

export const formValuesParamsSchema = object({
  activationDate: string().default(''),
  policyNumber: string().default(''),
});
