import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { array, boolean, number, object, string } from 'yup';

import { selectCurrentUser } from '@components/Auth';
import { useParseParams } from '@hooks/search-params';
import { getAge } from '@utils/datetime';
import { mergeInitialValues } from '@utils/formik';
import { ADULT_AGE_MAX, ADULT_AGE_MIN, CHILD_AGE_MAX, CHILD_AGE_MIN } from '@config';
import { StepType } from '@components/Base';

import { FormValues, formValuesParamsSchema } from './schema';

export const useInitialValues = () => {
  const currentUser = useSelector(selectCurrentUser);
  const params = useParseParams<FormValues>(formValuesParamsSchema);

  return useMemo(() => {
    if (!currentUser) return params;

    const { gender, dob } = currentUser;

    return mergeInitialValues(params, {
      me: {
        gender,
        dob,
        age: getAge(currentUser.dob),
      },
    });
  }, [currentUser]);
};

export const useSteps = () => {
  const { t } = useTranslation();

  return useMemo<StepType[]>(() => {
    const children = object({
      dob: string().required(t('validation:required')),
      age: number().positive().integer().min(CHILD_AGE_MIN).max(CHILD_AGE_MAX),
    });

    const adultAge = number().positive().integer().min(ADULT_AGE_MIN).max(ADULT_AGE_MAX);

    const steps: StepType[] = [
      {
        pathname: 'members',
        validationSchema: object({
          me: object({
            checked: boolean().oneOf([true]),
            gender: string().required(t('validation:required')),
          }),
        }),
      },
      {
        pathname: 'contact',
        validationSchema: object({
          me: object({
            dob: string().required(t('validation:required')),
            age: adultAge,
          }),
          spouse: object({
            checked: boolean(),
            dob: string().when('checked', {
              is: true,
              then: (schema) => schema.required(t('validation:required')),
            }),
            age: adultAge,
          }),
          sons: array().of(children),
          daughters: array().of(children),
        }),
      },
    ];

    return steps;
  }, []);
};
